
//*******************************
//* don't change this file
//* this file is auto generated
//*******************************

export const environment = {
  production: true,
  baseUrl: 'https://obo-nlp.kaitomm-health.obodroid.app',
  auth: {
    loginUrl: 'https://obo-auth.kaitomm-health.obodroid.app',
    clientId: 'user-zbx9gcwrjf9un2j8',
    redirectUri: 'https://obo-cms.kaitomm-health.obodroid.app/callback'
  },
};
